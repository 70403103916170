import { logger } from '@beacon/common/lang/log'
import { AssetMap, BrochureListing, Chronicle, ListingInfo } from '@beacon/common/types'
import { imageUrl } from './_helpers'
import { AnkorListing } from './types'
// import { extractAssets } from './extractAssets'

const log = logger({ package: 'provider', provider_name: 'ankor', f: 'asBrochureListing' })

/**
 *
 * @param content
 * @returns
 */
export const asBrochureListing = (
  content: AnkorListing,
  _listingInfo: ListingInfo,
  _chronicle: Chronicle,
  assetMap: AssetMap,
): BrochureListing => {
  log.trace({ content }, 'incoming listing content')
  const vessel: any = content?.vessel
  if (!content || !vessel) {
    throw new Error('no vessel found in content')
  }

  // TODO: add more fields
  const brochureListing = {
    name: vessel.blueprint.name,
    title: vessel.blueprint.name,
    images: assetMap.map(vessel.blueprint.images.map((image: string) => imageUrl('', image)) || []),
    accommodation: {
      accommodation: vessel.blueprint.cabinLayout
      .filter(({ label }: { label: string }) => label)
      .map(({ value, label }: { value: number; label: string }) => (value ? `${value}x ${label}` : label)),
      sleepsValue: vessel.blueprint.sleeps,
    },
    entertainment_and_av_equipment: vessel.blueprint.entertainment.filter((e: any) => e).join(', '),
    toys_and_tenders: vessel.blueprint.toys
      .filter(({ label }: { label: string }) => label)
      .map(({ quantity, label }: { quantity: number; label: string }) => (quantity ? `${quantity}x ${label}` : label))
      .concat(vessel.type.filter((e: any) => e))
      .concat(vessel.blueprint.amenities.map(({ quantity, label }: { quantity: number; label: string }) => (quantity ? `${quantity}x ${label}` : label)))
      .join(', '),
    hull_and_deck: {
      exterior_designer: vessel.blueprint.architect,
      hull_material: vessel.blueprint.hullConstruction,
      interior_designer: vessel.blueprint.interiorDesigner,
      superstructure_material: vessel.blueprint.superStructure,
    },
    main_spec: {
      length_meters: vessel.blueprint.length,
      model: vessel.blueprint.model,
      price: `${vessel.variants[0].pricing.currency} ${vessel.variants[0].pricing.total.toLocaleString()}`,
      beam: vessel.blueprint.beam,
      builder: vessel.blueprint.make,
      cabins: vessel.blueprint.cabins,
      type: vessel.type.filter((e: string) => e).join(', '),
      construction: vessel.blueprint.hullConstruction,
      crew: vessel.blueprint.maxCrew,
      decks: vessel.blueprint.decks,
      description: vessel.description,
      draft_length: vessel.blueprint.draft,
      exterior_designer: vessel.blueprint.architect,
      flag: vessel.blueprint.flag,
      gross_tonnage: vessel.blueprint.tonnage,
      interior_designer: vessel.blueprint.interiorDesigner,
      lying: vessel.blueprint.registryPort,
      main_image: imageUrl('', vessel.blueprint.hero) || '',
      mmsi_number: vessel.blueprint.mmsi,
      sleep: vessel.blueprint.sleeps,
      year_built: vessel.blueprint.builtYear,
      year_refit: vessel.blueprint.refitYear,
    },
    mechanical_equipment: {
      engine_1: {
        model: vessel.blueprint.engines,
      }
    },
    speed_capacity_and_weight: {
      cruise_speed: vessel.blueprint.cruiseSpeed,
      fuel_capacity: vessel.blueprint.fuelCapacity,
      fuel_consumption: vessel.blueprint.fuelPerHour,
      max_speed: vessel.blueprint.topSpeed,
    },
  }

  //
  return brochureListing
}
