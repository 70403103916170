<script setup lang="ts">
import { IndexRecord } from '@beacon/common/types'
import { getProviderLabel } from '@beacon/listings/services'
import { ClipboardDocumentIcon } from '@heroicons/vue/20/solid'
import { useCompareStore } from '../useCompareStore'
import { asRelativeTime, asYYYYLLLDD, priceFull, setBrokenImageBlank } from '../helpers'
import { capitalCase } from 'change-case'
import aa from 'search-insights'

type Props = {
  hit: IndexRecord
  queryID: string
}
const props = defineProps<Props>()

const compare = useCompareStore()
//
const _window_open = (url: string, target?: string) => {
  window.open(url, target)
}

/**
 * Return the "secret" brochure id with the cloaked brochure type injected
 * @param id The brochure id
 * @param brand use 'a' for Ahoy, 'r' for Ray White, 'p' for plain.
 */
const generateBrochureIdForProvider = (id: string, brand: string): string => {
  const start = id.substring(0, 3)
  const end = id.substring(3, id.length)
  return `${start}${brand}${end}`
}

// Open the brochure in a new tab
const openBrochure = (brochureId: string, brand: string, target: string = '_blank') => {
  aa('convertedObjectIDsAfterSearch', {
    index: 'global',
    eventName: 'brochure opened',
    objectIDs: [props.hit.objectID],
    queryID: props.queryID,
  })
  window.open(`https://sales.ahoyclub.com/listing/${generateBrochureIdForProvider(brochureId, brand)}`, target)
}

const openBrokerBrochure = (brochureId: string, brand: string, target: string = '_blank') => {
  aa('convertedObjectIDsAfterSearch', {
    index: 'global',
    eventName: 'brochure opened',
    objectIDs: [props.hit.objectID],
    queryID: props.queryID,
  })
  window.open(`https://yachtbac.com/listing/${generateBrochureIdForProvider(brochureId, brand)}`, target)
}

const openCharterBrochure = (brochureId: string, brand: string, target: string = '_blank') => {
  aa('convertedObjectIDsAfterSearch', {
    index: 'global',
    eventName: 'brochure opened',
    objectIDs: [props.hit.objectID],
    queryID: props.queryID,
  })
  window.open(`https://sales.ahoyclub.com/listing/charter/${generateBrochureIdForProvider(brochureId, brand)}`, target)
}

// Copy the brochure URL to the clipboard
const copyBrochureURL = async (brochureId: string, brand: string) => {
  aa('convertedObjectIDsAfterSearch', {
    index: 'global',
    eventName: 'brochure link copied',
    objectIDs: [props.hit.objectID],
    queryID: props.queryID,
  })
  const text = `https://sales.ahoyclub.com/listing/${generateBrochureIdForProvider(brochureId, brand)}`
  try {
    await navigator.clipboard.writeText(text)
  } catch ($e) {
    console.error('Failed to copy', $e)
  }
}

// Copy the brochure URL to the clipboard
const copyBrokerBrochureURL = async (brochureId: string, brand: string) => {
  aa('convertedObjectIDsAfterSearch', {
    index: 'global',
    eventName: 'brochure link copied',
    objectIDs: [props.hit.objectID],
    queryID: props.queryID,
  })
  const text = `https://yachtbac.com/listing/${generateBrochureIdForProvider(brochureId, brand)}`
  try {
    await navigator.clipboard.writeText(text)
  } catch ($e) {
    console.error('Failed to copy', $e)
  }
}

// List of the details fields to display.
const detailFields = [
  // { label: 'Asking Price', has: () => true, toString: (h: IndexRecord) => priceFull(h.priceAsking) || '&mdash;' },
  { label: 'Model', has: () => true, toString: (h: IndexRecord) => h.model },
  { label: 'Builder', has: () => true, toString: (h: IndexRecord) => h.builder },
  { label: 'Build Year', has: () => true, toString: (h: IndexRecord) => h.buildYear },
  {
    label: 'Length',
    has: () => true,
    toString: (h: IndexRecord) => `${h.length}m (${(h.length * 3.28084).toFixed(2)}ft)`,
  },
  { label: 'Name', has: (h: IndexRecord) => !!h.name, toString: (h: IndexRecord) => h.name },
  //
  {
    label: 'Beam',
    has: (h: IndexRecord) => !!h.beam,
    toString: (h: IndexRecord) => (h.beam ? `${h.beam}m (${(h.beam * 3.28084).toFixed(2)}ft)` : '&mdash;'),
  },
  { label: 'Cabins', has: (h: IndexRecord) => !!h.cabins, toString: (h: IndexRecord) => h.cabins },
  { label: '# Decks', has: (h: IndexRecord) => !!h.decksCount, toString: (h: IndexRecord) => h.decksCount },
  {
    label: 'Draft',
    has: (h: IndexRecord) => !!h.draft,
    toString: (h: IndexRecord) => (h.draft ? `${h.draft}m (${(h.draft * 3.28084).toFixed(2)}ft)` : '&mdash;'),
  },
  { label: '# Engines', has: (h: IndexRecord) => !!h.engineCount, toString: (h: IndexRecord) => h.engineCount },
  { label: 'Engine Hours', has: (h: IndexRecord) => !!h.engineHours, toString: (h: IndexRecord) => h.engineHours },
  { label: 'Flag', has: (h: IndexRecord) => !!h.flag, toString: (h: IndexRecord) => h.flag },
  {
    label: 'Fuel Capacity (L)',
    has: (h: IndexRecord) => !!h.fuelCapacity,
    toString: (h: IndexRecord) => h.fuelCapacity,
  },
  { label: 'Refit Type', has: (h: IndexRecord) => !!h.refitType, toString: (h: IndexRecord) => h.refitType },
  { label: 'Refit Year', has: (h: IndexRecord) => !!h.refitYear, toString: (h: IndexRecord) => h.refitYear },
  {
    label: 'Crusing Speed (knots)',
    has: (h: IndexRecord) => !!h.speedCrusing,
    toString: (h: IndexRecord) => h.speedCrusing,
  },
  { label: 'Max Speed (knots)', has: (h: IndexRecord) => !!h.speedMax, toString: (h: IndexRecord) => h.speedMax },
  {
    label: 'Location',
    has: (h: IndexRecord) => !!h.location,
    toString: (h: IndexRecord) => h.location && h.location.join(', '),
  },
]
</script>
<template>
  <div class="w-full min-h-32 p-4 grid grid-cols-1 gap-y-4 bg-indigo-50">
    <!-- Detail Info Block -->
    <div class="w-full grid grid-cols-1 gap-y-4">
      <div class="w-full flex flex-auto">
        <!-- Detail fields -->
        <div class="basis-1/2 grow">
          <dl class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- Pricing first -->
            <div v-if="hit.price" class="flex flex-nowrap gap-x-3">
              <dt class="font-semibold text-gray-600">Pricing</dt>
              <dd>
                <template v-for="price in hit.price" :key="price.label">
                  <div class="whitespace-nowrap" v-if="price.label !== 'min' && price.label !== 'max'">
                    {{ price.label }}
                    <span class="text-gray-900" v-html="priceFull(price)"></span>
                    <span v-if="price.unit"> / {{ capitalCase(price.unit) }}</span>
                  </div>
                </template>
              </dd>
            </div>

            <template v-for="detail in detailFields" :key="detail.label">
              <div v-if="detail.has(hit)" class="flex flex-nowrap gap-x-3">
                <dt class="font-semibold text-gray-600">{{ detail.label }}</dt>
                <dd class="text-gray-900" v-html="detail.toString(hit)"></dd>
              </div>
            </template>
          </dl>
        </div>

        <!-- Brochures -->
        <div class="basis-1/4 grow">
          <div class="grid grid-cols-1 gap-3">
            <!-- span class="font-semibold">Links</span -->
            <div class="flex gap-x-2" v-if="!hit.labels?.includes('charter')">
              <button
                type="button"
                @click="openBrochure(hit.brochureId, 'a')"
                class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal focus:ring-1 focus:outline-none focus:ring-ahoy-club-teal"
              >
                Ahoy Club
              </button>
              <button
                type="button"
                class="w-8 h-auto flex items-center duration-200 justify-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal"
                @click="[copyBrochureURL(hit.brochureId, 'a'), $emit('showCopySuccess')]"
              >
                <ClipboardDocumentIcon class="h-4 w-4 align-middle text-center" />
              </button>
            </div>

            <div class="flex gap-x-2 hidden" v-if="hit.labels?.includes('charter')">
              <button
                type="button"
                @click="openCharterBrochure(hit.brochureId, 'a')"
                class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal focus:ring-1 focus:outline-none focus:ring-ahoy-club-teal"
              >
                Ahoy Club
              </button>
              <button
                type="button"
                class="w-8 h-auto flex items-center duration-200 justify-center text-white bg-ahoy-club-blue rounded-lg hover:bg-ahoy-club-teal-secondary hover:text-ahoy-club-teal"
                @click="[copyBrochureURL(hit.brochureId, 'a'), $emit('showCopySuccess')]"
              >
                <ClipboardDocumentIcon class="h-4 w-4 align-middle text-center" />
              </button>
            </div>

            <div class="flex gap-x-2" v-if="!hit.labels?.includes('charter')">
              <button
                type="button"
                @click="openBrochure(hit.brochureId, 'r')"
                class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-[#595959] bg-[#ffe512] rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-[#ffe512]"
              >
                Ray White Marine
              </button>
              <button
                type="button"
                class="w-8 h-auto flex items-center duration-200 justify-center text-[#595959] bg-[#ffe512] rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-[#ffe512]"
                @click="[copyBrochureURL(hit.brochureId, 'r'), $emit('showCopySuccess')]"
              >
                <ClipboardDocumentIcon class="h-4 w-4 align-middle text-center" />
              </button>
            </div>

            <div class="flex gap-x-2" v-for="link in hit.links">
              <button
                type="button"
                @click="_window_open(link.href, '_blank')"
                :class="`w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-ahoy-club-blue bg-gray-400 rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-gray-400 ${link.class ? link.class : ''}`"
              >
                {{ link.text }}
              </button>
            </div>

            <div class="flex gap-x-2" v-if="!hit.labels?.includes('charter')">
              <button
                type="button"
                @click="openBrokerBrochure(hit.brochureId, 'b')"
                class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-ahoy-club-blue bg-gray-400 rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-gray-400"
              >
                Broker Friendly
              </button>
              <button
                type="button"
                class="w-8 h-auto flex items-center duration-200 justify-center text-ahoy-club-blue bg-gray-400 rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-gray-400"
                @click="[copyBrokerBrochureURL(hit.brochureId, 'b'), $emit('showCopySuccess')]"
              >
                <ClipboardDocumentIcon class="h-4 w-4 align-middle text-center" />
              </button>
            </div>

            <div class="flex gap-x-2" v-if="!hit.labels?.includes('charter')">
              <button
                type="button"
                @click="compare.add(hit)"
                class="w-44 py-2 px-3 flex items-center duration-200 justify-center text-sm font-medium text-center text-ahoy-club-blue bg-gray-400 rounded-lg hover:bg-gray-800 hover:text-white focus:ring-1 focus:outline-none focus:ring-gray-400"
              >
                Add to compare
              </button>
            </div>
          </div>
        </div>

        <!-- Broker, Provider -->
        <div class="basis-1/4 grow">
          <div v-if="hit.broker" class="grid grid-cols-1 gap-2 pb-8">
            <span class="font-semibold">Broker</span>
            <div class="flex flex-col">
              <div class="font-medium">{{ hit.broker?.company }}</div>
              <div class="">{{ hit.broker?.name }}</div>
              <div class="">{{ hit.broker?.email }}</div>
              <div class="">{{ hit.broker?.phoneNumber }}</div>
            </div>
          </div>
          <div class="grid grid-cols-1 gap-2">
            <span class="font-semibold">Listing Provider</span>
            <div class="flex flex-wrap gap-8">
              <div class="grid grid-cols-1 gap-2">
                <dl class="grid grid-cols-1 gap-2">
                  <div class="flex flex-nowrap gap-x-3">
                    <dt class="font-semibold text-gray-600">Source</dt>
                    <dd
                      class="text-gray-900 whitespace-nowrap"
                      v-html="getProviderLabel(hit.provider) || '&mdash;'"
                    ></dd>
                  </div>
                  <div class="flex flex-nowrap gap-x-3">
                    <dt class="font-semibold text-gray-600">ID</dt>
                    <dd class="text-gray-900">{{ hit.providerId }}</dd>
                  </div>
                </dl>
              </div>
              <div class="flex flex-col gap-1 whitespace-nowrap">
                <div class="text-xs text-gray-400">
                  Listing Checked: <span v-html="asRelativeTime(hit.tsChecked) || '&mdash;'"></span>
                </div>
                <div class="text-xs text-gray-400">
                  Listing Updated: <span v-html="asYYYYLLLDD(hit.tsListingUpdated) || '&mdash;'"></span>
                </div>
                <div class="text-xs text-gray-400">
                  Listing Published: <span v-html="asYYYYLLLDD(hit.tsListingPublished) || '&mdash;'"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- nunununnununununu -->
    <!-- *     Images    * -->
    <!-- nunununnununununu -->
    <ul class="flex flex-wrap w-full gap-2 justify-start">
      <li v-for="src in hit.images">
        <picture>
          <source srcset="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" media="(max-width: 256px)" />
          <img
            class="rounded-lg w-auto h-64 object-contain duration-300 z-10"
            :src="`${src}`"
            loading="lazy"
            @error="setBrokenImageBlank"
            alt=""
          />
        </picture>
      </li>
      <li
        v-if="hit.images && hit.images.length != hit.imagesCount"
        class="w-64 h-64 bg-gray-200 rounded-md flex flex-col items-center justify-start"
      >
        <div class="text-center">
          <div class="text-5xl pb-4">+ {{ hit.imagesCount! - hit.images.length }}</div>
          <div>more in the brochure!</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<style lang="scss">
.ankor-link {
  background-color: #6b46c1 !important; /* bg-purple-600 */
  color: #ffffff !important; /* text-white */
}
.ankor-link:hover {
  background-color: #4c51bf !important; /* hover:bg-purple-800 */
}
</style>
